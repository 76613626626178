<template>
  <div class="course-library-add">
    <el-card>
      <div slot="header" class="clearfix">
        <span class="title">{{ '班级数据概览' }}</span>
      </div>
      <div class="main">
        <div class="item-total">
          <span class="item-dc">总班级数量</span>
          <p class="item-num">{{ classTotal.classTotal }}</p>
        </div>
        <div class="item-total">
          <span class="item-dc">已报名人数</span>
          <p class="item-num">{{ classTotal.joinTotal }}</p>
        </div>
        <div class="item-total">
          <span class="item-dc">总学习时长</span>
          <p class="item-num">{{ classTotal.studyTotalTime }}</p>
        </div>
        <div class="item-total">
          <span class="item-dc">平均学习时长</span>
          <p class="item-num">{{ classTotal.studyAvgTime }}</p>
        </div>
      </div>
      <div class="main">
        <div class="item-total">
          <span class="item-dc">班级课程数</span>
          <p class="item-num">{{ classTotal.classCourseTotal }}</p>
        </div>
        <div class="item-total">
          <span class="item-dc">培训中人数</span>
          <p class="item-num">{{ classTotal.trainingTotal }}</p>
        </div>
        <div class="item-total">
          <span class="item-dc">培训中班级数量</span>
          <p class="item-num">{{ classTotal.classTrainingTotal }}</p>
        </div>
        <div class="item-total">
          <span class="item-dc">已结训班级数量</span>
          <p class="item-num">{{ classTotal.classEndTotal }}</p>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import { classdataStatistics } from '@/api/class'
import to from 'await-to'
export default {
  name: 'ClassTotal',
  data() {
    return {
      loading: false,
      classTotal: [],
    }
  },
  mounted() {
    this.getClassesData()
  },
  methods: {
    async getClassesData() {
      if (this.loading) return
      this.loading = true
      const [res, err] = await to(classdataStatistics({}))
      this.loading = false
      if (err) return this.$message.warning(err.msg)
      this.classTotal = res.data
    },
  },
}
</script>

<style scoped>
.main {
  display: flex;
  margin-bottom: 20px;
}
.item-total {
  width: 261px;
  height: 145px;
  background: #ffffff;
  border: 1px solid #d5d5d5;
  border-radius: 5px;
  margin-top: 10px;
  margin-right: 30px;
}
.item-dc {
  display: inline-block;
  font-size: 14px;
  font-family: Source Han Sans CN, Source Han Sans CN-Regular;
  font-weight: 400;
  text-align: left;
  color: #909399;
  margin: 22px 0 0 17px;
}
.item-num {
  font-size: 30px;
  font-family: Source Han Sans CN, Source Han Sans CN-Medium;
  font-weight: 500;
  text-align: left;
  color: #333333;
  margin-left: 30px;
}
</style>
