<!--
 * @Description: 章节管理
 * @Author: xiawenlong
 * @Date: 2021-04-26 14:12:39
 * @LastEditors: xiawenlong
 * @LastEditTime: 2021-04-28 14:55:30
-->
<template>
  <div class="course-library-section">
    <el-card>
      <div slot="header" class="clearfix">
        <span class="title">章节管理</span>
      </div>
      <el-row class="content">
        <el-col :span="8">
          <div class="section">
            <div class="title">{{ courseName }}——章节</div>
            <div class="th">
              <el-checkbox
                v-model="allChecked"
                :indeterminate="isIndeterminate"
                @change="handleAllCheck"
              ></el-checkbox>
              <span>视频名称 </span>
              <span>操作</span>
            </div>
            <el-checkbox-group v-model="checkedSections" @change="handleCheckedSectionsChange">
              <draggable v-model="sectionList" class="drag">
                <transition-group>
                  <div
                    v-for="(item, index) in sectionList"
                    :key="item.materialId"
                    class="section-item"
                  >
                    <el-checkbox v-model="item.checked" :label="index"></el-checkbox>
                    <div class="name">
                      <div v-if="item.edit" class="input">
                        <el-input v-model="item.editName" size="mini"></el-input>
                        <el-button type="primary" @click="itemEditSave(item)">保存</el-button>
                        <el-button @click="item.edit = false">取消</el-button>
                      </div>
                      <span v-else>{{ item.sectionName }}</span>
                    </div>
                    <div class="icons">
                      <i title="编辑" class="el-icon-edit-outline" @click="item.edit = true"></i>
                      <i title="下移" class="el-icon-bottom" @click="itemDown(item, index)"></i>
                      <i title="上移" class="el-icon-top" @click="itemUp(item, index)"></i>
                      <i
                        title="删除"
                        class="el-icon-remove-outline"
                        @click="itemDelete(item, index)"
                      ></i>
                    </div>
                  </div>
                </transition-group>
              </draggable>
            </el-checkbox-group>
          </div>
        </el-col>
        <el-col :span="3">
          <div class="btns">
            <el-button icon="el-icon-arrow-left" @click="addVideo">添加视频 </el-button>
            <el-button style="margin-bottom:40px" icon="el-icon-arrow-right" @click="removeVideo"
              >移除视频</el-button
            >
            <el-button type="primary" @click="submit">确定完成添加</el-button>
            <el-button @click="back">返回课程列表</el-button>
          </div>
        </el-col>
        <el-col :span="13">
          <table-list
            ref="courseVideoTable"
            title="课程资源库"
            :loading="loading"
            :data="videoList"
            :search-form="searchForm(this)"
            :columns="columns(this)"
            :pager="pager"
            :options="{
              selection: true,
              reserveSelection: true,
              rowKey: 'materialId',
              selectable: selectEvent,
            }"
            @search="onSearch"
            @selectionChange="handleSelectionChange"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          ></table-list>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>
<script>
// 搜索框配置
const searchForm = () => [
  // {
  //   label: '课程类型',
  //   type: 'select',
  //   prop: 'courseType',
  //   defaultValue: 0,
  //   children: [
  //     { value: 0, label: '录播' },
  //     { value: 1, label: '直播' },
  //   ],
  // },
  {
    label: '课程名称',
    prop: 'keyword',
  },
]
// 表格列配置
// const columns = () => [
//   {
//     prop: 'videoId',
//     label: '视频ID',
//   },
//   {
//     prop: 'groupName',
//     width: 100,
//     label: '分组',
//   },
//   {
//     prop: 'videoName',
//     label: '视频名称',
//   },
//   {
//     prop: 'teacherName',
//     width: 100,
//     label: '讲师',
//   },
//   {
//     prop: 'duration',
//     width: 100,
//     label: '视频时长',
//   },
//   {
//     prop: 'uploadTime',
//     width: 180,
//     label: '上传时间',
//   },
// ]

import draggable from 'vuedraggable'
import TableList from '@/components/TableList'
import { courseSections, courseVideo, courseSectionSubmit, liveSearch } from '@/api/course'
import to from 'await-to'
export default {
  name: 'CourseLibrarySection',
  components: { draggable, TableList },
  data() {
    if (this.$route.query.courseType == 0) {
      var columns = () => [
        {
          prop: 'videoId',
          label: '视频ID',
        },
        {
          prop: 'groupName',
          width: 100,
          label: '分组',
        },
        {
          prop: 'videoName',
          label: '视频名称',
        },
        {
          prop: 'teacherName',
          width: 100,
          label: '讲师',
        },
        {
          prop: 'duration',
          width: 100,
          label: '视频时长',
        },
        {
          prop: 'uploadTime',
          width: 180,
          label: '上传时间',
        },
      ]
    } else {
      columns = () => [
        {
          prop: 'liveId',
          label: '直播ID',
        },
        {
          prop: 'liveName',
          label: '直播名称',
        },
        {
          prop: 'teacherName',
          width: 180,
          label: '讲师名称',
        },
        {
          prop: 'liveTime',
          width: 300,
          label: '直播时间',
        },
      ]
    }
    return {
      type: this.$route.query.courseType,
      searchForm,
      columns,
      loading: false,
      courseName: '',
      allChecked: false,
      isIndeterminate: false,
      checkedSections: [],
      sectionList: [],
      videoList: [],
      selectCourse: [],
      pager: {
        total: 0,
        size: 10,
        current: 1,
      },
    }
  },
  mounted() {
    this.courseName = this.$route.query.courseName
    this.getSections()
    this.getCourseVideo()
  },
  methods: {
    async getCourseVideo(queryParams = {}) {
      if (this.loading) return
      const { current, size } = this.pager
      this.loading = true
      if (this.type == 0) {
        const [res, err] = await to(courseVideo({ courseType: 0, current, size, ...queryParams }))
        this.loading = false
        if (err) return this.$message.warning(err.msg)
        this.videoList = res.data.list
        this.pager.total = res.data.total
        this.sectionList.forEach(video => {
          const index = this.videoList.findIndex(item => item.materialId === video.materialId)
          if (index >= 0) this.videoList[index].flag = true
        })
      } else {
        const [res, err] = await to(liveSearch({ courseType: 1, current, size, ...queryParams }))
        this.loading = false
        if (err) return this.$message.warning(err.msg)
        this.videoList = res.data.list
        this.pager.total = res.data.total
        this.sectionList.forEach(video => {
          const index = this.videoList.findIndex(item => item.materialId === video.materialId)
          if (index >= 0) this.videoList[index].flag = true
        })
      }
    },
    async getSections() {
      const { courseId } = this.$route.params
      const [res, err] = await to(courseSections({ courseId }))
      if (err) return this.$message.warning(err.msg)
      this.sectionList = res.data
      this.sectionList.map(item => {
        this.$set(item, 'checked', false)
        this.$set(item, 'edit', false)
        this.$set(item, 'editName', item.sectionName)
      })
    },
    // 查询按钮
    onSearch(queryParams) {
      this.pager.current = 1
      this.getCourseVideo(queryParams)
    },
    handleAllCheck(v) {
      this.checkedSections = v ? this.sectionList.map((item, index) => index) : []
      this.isIndeterminate = false
    },
    handleCheckedSectionsChange(v) {
      let checkedCount = v.length
      this.allChecked = checkedCount === this.sectionList.length
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.sectionList.length
    },
    itemDown(item, index) {
      if (index == this.sectionList.length - 1) {
        return this.$message.warning('已经是最后一项了')
      }
      this.sectionList.splice(index + 2, 0, this.sectionList[index])
      this.sectionList.splice(index, 1)
    },
    itemUp(item, index) {
      if (index == 0) {
        return this.$message.warning('已经是第一项了')
      }
      this.sectionList.splice(index - 1, 0, this.sectionList[index])
      this.sectionList.splice(index + 1, 1)
    },
    itemDelete(item, index) {
      this.sectionList.splice(index, 1)
    },
    itemEditSave(item) {
      item.sectionName = item.editName
      item.edit = false
    },
    addVideo() {
      this.sectionList = [
        ...this.sectionList,
        ...this.selectCourse.map(item => ({
          ...item,
          sectionName: item.videoName || item.liveName,
          checked: false,
          edit: false,
          editName: item.videoName,
        })),
      ]
      this.selectCourse.forEach(video => {
        const index = this.videoList.findIndex(item => item.materialId === video.materialId)
        this.videoList[index].flag = true
      })
      this.$refs.courseVideoTable.$refs.tableList.clearSelection()
    },
    removeVideo() {
      console.log(this.checkedSections)
      if (!this.checkedSections.length) return
      for (let i = this.checkedSections.length - 1; i >= 0; i--) {
        const index = this.checkedSections[i]
        const videoIndex = this.videoList.findIndex(
          item => item.materialId === this.sectionList[index].materialId,
        )
        if (videoIndex >= 0) {
          this.videoList[i].flag = false
        }
        this.sectionList.splice(index, 1)
      }
      this.isIndeterminate = false
      this.checkedSections = []
    },
    async submit() {
      const { courseId } = this.$route.params
      const sections = this.sectionList.map((item, index) => ({
        chapterId: item.chapterId || '',
        sectionId: item.sectionId || '',
        sectionName: item.sectionName,
        sectionSort: index,
        materialId: item.materialId,
        materialCategory: this.type == 0 ? 1 : 4,
      }))
      console.log(this.sectionList)
      const [, err] = await to(courseSectionSubmit({ courseId, sections }))
      if (err) return this.$message.warning(err.msg)
      this.$message.success('保存成功')
      this.back()
    },
    back() {
      this.$router.push('/course/library')
    },
    // 多选
    handleSelectionChange(val) {
      this.selectCourse = val
    },
    // 分页
    handleSizeChange(size) {
      this.pager.current = 1
      this.pager.size = size
      this.getCourseVideo()
    },
    handleCurrentChange(current) {
      this.pager.current = current
      this.getCourseVideo()
    },
    selectEvent(row) {
      return !row.flag
    },
  },
}
</script>
<style lang="scss" scoped>
.course-library-section {
  .content {
    .section {
      border: 1px solid #dcdfe6;
      .title {
        height: 40px;
        line-height: 40px;
        font-size: 18px;
        padding-left: 20px;
        font-weight: 500;
      }
      .th {
        border-top: 1px solid #dcdfe6;
        font-size: 14px;
        color: #979797;
        padding: 10px 20px;
        display: flex;
        span {
          &:first-of-type {
            flex: 1;
            margin-left: 20px;
          }
          &:last-of-type {
            width: 80px;
          }
        }
      }
      .drag {
        overflow: hidden;
        .section-item {
          font-size: 16px;
          padding: 10px 20px;
          // border-bottom: 1px dashed #ccc;
          &:nth-of-type(2n-1) {
            background-color: #e8e8e8;
          }
          &:last-of-type {
            border-bottom: 0;
          }
          display: flex;
          align-items: center;
          ::v-deep.el-checkbox__label {
            display: none;
          }
          .name {
            cursor: move;
            flex: 1;
            margin-left: 20px;
            .input {
              display: flex;
              .el-input {
                margin-right: 10px;
              }
              .el-button {
                width: 50px;
                height: 28px;
                font-size: 12px;
                margin-right: 5px;
                margin-left: 0;
              }
            }
          }
          .icons {
            width: 80px;
            display: flex;
            align-items: center;
            i {
              cursor: pointer;
              margin-right: 4px;
              border-radius: 50%;
              width: 16px;
              height: 16px;
              line-height: 16px;
              text-align: center;
              color: #333;
              &:nth-of-type(2),
              &:nth-of-type(3) {
                box-sizing: border-box;
                font-size: 12px;
                color: #fff;
                background: #ff7b33;
              }
              &:nth-of-type(4) {
                color: #f8341e;
              }
            }
          }
        }
      }
    }
    .btns {
      padding-top: 100px;
      .el-button {
        display: block;
        margin: 20px auto;
      }
    }
    ::v-deep.table-list {
      .table-search-bar {
        padding: 0;
      }
      .el-checkbox__input.is-disabled .el-checkbox__inner {
        display: none;
      }
    }
  }
}
</style>
